<template>
	<div :style="`min-height:${windowHeight}px;`" class="is-flex flex-ai-center bg-color-primary">
		<div class="container padding-x-mobile pd-y-3" >
			<div class="card rounded">
				<div class="columns is-marginless ">
					<div class="column is-paddingless is-5 is-hidden-mobile">
						<div class="bgSignin">

						</div>
					</div>
					<div class="column is-7 ":style="maxWidth > 1170 ? 'padding-right: 1.75rem;':''" :class="maxWidth > 1170 ? '':'pd-5'">
						<slot></slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		data(){
			return {
				maxWidth: window.innerWidth,
				windowHeight:window.innerHeight
			}
		},
	}
</script>
<style scoped>
@media screen and (min-width: 100px) and (max-width: 767px) {
	.padding-x-mobile{
		padding: 0 15px;
	}
}
	@media screen and (min-width: 1216px){
		.container {
		    max-width: 1170px;
		}
	}
	.bgSignin{
		min-height: 650px;
		background-position: left top;
		background-repeat: no-repeat;
		background-size: inherit;
		background-image: url('../assets/bg-signin.png');
	}
</style>