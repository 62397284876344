<template>
	<HomeTamplate>
		<div class="columns flex-wrap">
			<div class="column is-12" :class="maxWidth > 1170 ? 'has-text-right':'has-text-centered'">
				<a :href="webAvocado"><img src="@/assets/logoAvocado.png" /></a>
			</div>
			<div class="column has-text-left is-9 mg-x-auto">
				<h1 class="is-size-3 has-text-centered mg-b-5 text-color-primary">ลืมรหัสผ่าน?</h1>
				<form @submit.prevent="sendEmail()">
					<b-field label="กรุณากรอกอีเมลเพื่อเปลี่ยนรหัสผ่าน" class="mg-b-5"
						:type="{'is-danger':errors.has('email')}"
	                    :message="errors.has('email')?'Please fill out email':''">
			            <b-input rounded name="email" v-validate="'required'" v-model="form.email"></b-input>
			        </b-field>
			        <div class="column is-6  mg-y-5 mg-x-auto ">
			        	<div class="buttons">
			        		<button type="submit" class="pd-y-5 bt-color-avocado is-rounded w-100 button is-info" rounded expanded>ขอรหัสผ่านใหม่</button>
			        	</div>
			        </div>
			    </form>
		        <div class="column is-6 has-text-centered mg-y-5 mg-x-auto ">
		        	<router-link to="sign-in">
		        		ย้อนกลับ
		        	</router-link>
		        </div>
			</div>
		</div>
	</HomeTamplate>
</template>
<script>
import {HTTP} from '@/service/axios'
import HomeTamplate from '@/template/HomeTemplate'
	export default{
		components:{
			HomeTamplate
		},
		data(){
			return {
				form:{
					email:'',
				},
				maxWidth: window.innerWidth,
				windowHeight:window.innerHeight,
				webAvocado: process.env.VUE_APP_WEB_AVOCADO,
			}
		},
		methods:{
			sendEmail(){
				this.$validator.validate().then(async(valid) => {
               		if (valid) {
               			HTTP.post('/forgot/password',this.form).then(res=>{
               				if(res.data.success){
               					this.$buefy.toast.open({
									duration: 5000,
				                    message: 'เราได้ส่งอีเมลไปให้คุณเพื่อใช้ในการเปลี่ยนรหัสผ่านเรียบร้อยแล้ว',
				                    type: 'is-success'
				                })
               				}
               			})
               		}
               	})
			}
		}
	}
</script>
<style scoped>
	
	
</style>